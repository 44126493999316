* {
  font-family: "Inter", "Montserrat", "Roboto", "Ubuntu", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #07283b;
  background-color: #fff;
}

div,
ul,
ol,
li,
a,
span,
table,
textarea,
tr,
td {
  box-sizing: border-box;
}
